<template>
  <v-container fluid>
    <v-toolbar flat color="transparent" class="fixed-bar-b">
      <v-btn
        x-small
        color="warning"
        fab
        @click="dialog_suppliers = !dialog_suppliers"
        class="mr-2"
      >
        <v-icon> mdi-magnify </v-icon>
      </v-btn>
      <!-- <v-toolbar-title v-if="this.supplier_selected.name">{{
            this.supplier_selected.group_name
          }}</v-toolbar-title> -->
      <v-spacer></v-spacer>

      <v-spacer></v-spacer>
      <v-btn
        color="orange"
        v-if="supplier_selected.code"
        fab
        x-small
        dark
        @click="dialog_smart = !dialog_smart"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <v-data-table
      :headers="headers[$vuetify.breakpoint.xsOnly]"
      :items="items"
      no-data-text="No hay productos registrados"
      :items-per-page="-1"
      item-key="product_name"
      mobile-breakpoint="0"
      :search="search"
      sort-by="category,product_name"
      class="table-cursor mt-3"
      @click:row="handleClick"
    >
      <template v-slot:top> </template>

      <template v-slot:[`item.picture`]="{ item }" v-if="item.picture !== ''">
        <v-avatar>
          <v-img
            :src="getImagePhoto(item.account, '000000', item.picture)"
          ></v-img>
        </v-avatar>
      </template>
      <template v-slot:[`item.name`]="{ item }" class="pa-0 ma-0">
        <b>{{ item.product_name }}</b>
        <span v-if="$vuetify.breakpoint.xsOnly">
          <small> ({{ item.category }})</small><br />
          <small> {{ item.size }} {{ item.uom }} </small>
        </span>
      </template>
      <template v-slot:[`item.size`]="{ item }">
        {{ item.size + " " + item.uom }} <br />
      </template>

      <template v-slot:[`item.cost`]="{ item }">
        {{ "$ " + parseFloat(item.cost).toLocaleString(2) }}
      </template>
    </v-data-table>

    <ProductForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      :categories="categories"
      :store="store"
      @close="dialog = false"
      :subcategories="subcategories"
      :brands="brands"
      :suppliers="this.supplier_selected.code"
      @refresh="refresh()"
    />

    <v-bottom-navigation dark app>
      <v-toolbar color="#075E54">
        <v-row justify="center">
          <v-col md="6" cols="12">
            <v-text-field
              v-model="search"
              hide-details
              append-icon="mdi-magnify"
              dense
              outlined
              rounded
              placeholder="Buscar producto ..."
            />
          </v-col>
        </v-row>
      </v-toolbar>
    </v-bottom-navigation>

    <v-navigation-drawer
      v-model="dialog_suppliers"
      fixed
      left
      width="450px"
      app
    >
      <v-toolbar dark color="#128C7E" class="fixed-bar">
        <v-toolbar-title>Proveedores</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="dialog_suppliers = !dialog_suppliers"
          >mdi-arrow-left</v-icon
        >
      </v-toolbar>

      <v-data-table
        :headers="headers_suppliers"
        :items="suppliers"
        :items-per-page="-1"
        item-key="code"
        sort-by="name"
        :search="search_supplier"
        mobile-breakpoint="0"
        class="table-cursor elevation-1"
        loading-text="Cargando ..."
        @click:row="get_items"
      >
        <template v-slot:[`item.picture`]="{ item }" class="pa-0 ma-0">
          <v-avatar>
            <v-img
              v-if="item.picture"
              :src="getImagePhoto(item.account, item.code, item.picture)"
            ></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.name`]="{ item }" class="pa-0 ma-0">
          <b>{{ item.name }}</b>
          <span>
            <small> ({{ item.group_name }})</small><br />
            <small> {{ item.address }} {{ item.mobile }} </small>
          </span>
        </template>
      </v-data-table>

      <v-bottom-navigation dark app>
        <v-toolbar color="#128C7E">
          <v-row justify="center">
            <v-col md="12" cols="12">
              <v-text-field
                v-model="search_supplier"
                hide-details
                append-icon="mdi-magnify"
                outlined
                rounded
                dense
                placeholder="Buscar proveedor ..."
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </v-bottom-navigation>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="dialog_smart"
      fixed
      right
      :width="smart_width"
      app
    >
      <v-toolbar dark color="#128C7E" class="fixed-bar">
        <v-btn x-small fab class="mr-2" @click="s_expand = !s_expand">
          <v-icon>mdi-arrow-split-vertical</v-icon>
        </v-btn>
        <v-toolbar-title>Smart List</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="dialog_smart = !dialog_smart">mdi-arrow-right</v-icon>
      </v-toolbar>
      <v-toolbar color="teal lighten-4" class="fixed-bar-b">
        <v-select
          rounded
          :items="smart_categories"
          outlined
          dense
          hide-details="auto"
          @change="changeMenu"
        />
        <v-spacer></v-spacer>
        <v-btn x-small color="warning" fab @click="newForm()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <v-tabs
        class="align-with-title"
        background-color="white"
        light
        show-arrows
        centered
        slider-color="orange"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="category in smart_categories"
          :key="category"
          :href="'#tab-' + category"
          @click="changeMenu(category)"
        >
          {{ category }}
        </v-tab>
      </v-tabs> -->

      <!-- <v-expansion-panels>
      <v-expansion-panel
         v-for="category in smart_categories"
          :key="category"
      >
        <v-expansion-panel-header>
           {{ category }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->

      <v-data-table
        :headers="headers[!s_expand]"
        :items="smart_lst_selected"
        :items-per-page="15"
        item-key="code"
        sort-by="name"
        :search="search_smart"
        mobile-breakpoint="0"
        class="table-cursor elevation-1"
        loading-text="Cargando ..."
        @click:row="add_to_supplier"
      >
        <template v-slot:[`item.picture`]="{ item }" v-if="item.picture !== ''">
          <v-avatar v-if="item.picture">
            <v-img
              :src="getImagePhoto(item.account, item.store, item.picture)"
            ></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.name`]="{ item }" class="pa-0 ma-0">
          <b>{{ item.product_name }}</b>
          <span v-if="$vuetify.breakpoint.xsOnly">
            <small> ({{ item.category }})</small><br />
            <small> {{ item.size }} {{ item.uom }} </small>
          </span>
        </template>
        <template v-slot:[`item.size`]="{ item }">
          {{ item.size + " " + item.uom }} <br />
        </template>

        <template v-slot:[`item.price`]="{ item }">
          {{ "$ " + parseFloat(item.price).toLocaleString(2) }}
        </template>
      </v-data-table>

      <v-bottom-navigation dark app>
        <v-toolbar color="#128C7E">
          <v-row justify="center">
            <v-col md="12" cols="12">
              <v-text-field
                v-model="search_smart"
                hide-details
                append-icon="mdi-magnify"
                outlined
                rounded
                dense
                placeholder="Buscar producto Smart ..."
                @keypress="update_selected()"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </v-bottom-navigation>
    </v-navigation-drawer>

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createItem from "../../utils/createItem.js";
import ProductForm from "../../components/RawMaterialForm.vue";
import createStore from "../../utils/createStore.js";

export default {
  components: { ProductForm },
  data() {
    return {
      search_smart: "",
      s_expand: false,
      smart_width: 450,
      dialog_smart: false,
      smart_categories: [],
      smart_lst_selected: [],
      header_size: true,
      table: "raw_material",
      search: "",
      search_supplier: "",
      dialog_suppliers: true,
      errors: [],
      store: "",
      storesLst: [],
      valid: false,
      dialog: false,
      newItem: false,
      loading_status: false,
      byCategories: [],
      categories: [],
      subcategories: [],
      brands: [],
      suppliers: [],
      supplier_dialog: false,
      supplier_selected: createStore(),
      headers: {
        false: [
          {
            text: "",
            align: "center",
            value: "picture",
            width: 50,
          },
          {
            text: "Categoría",
            align: "start",
            sortable: true,
            value: "category",
            dataType: "text",
          },
          {
            text: "Marca",
            align: "start",
            sortable: true,
            value: "brand",
            dataType: "text",
          },
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Tamaño",
            align: "center",
            sortable: true,
            value: "size",
            dataType: "text",
          },
          {
            text: "Costo",
            align: "end",
            sortable: true,
            value: "cost",
            dataType: "number",
          },
          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "price",
            dataType: "number",
          },
        ],
        true: [
          {
            text: "",
            align: "center",
            value: "picture",
            width: 50,
          },
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Costo",
            align: "end",
            sortable: true,
            value: "cost",
            dataType: "number",
          },
        ],
      },
      headers_suppliers: [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "picture",
          dataType: "text",
          width: 40,
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
          dataType: "text",
        },
      ],
      items: [],
      smart_lst: [],
      smart_ctgry: [],
      item: createItem(),
      edit: false,
    };
  },
  mounted() {
    this.storesLst = window.stores;
    //this.supplier.account="000000";
    this.get_suppliers();
    this.get_smart_lst();
  },
  methods: {
    update_selected() {
      this.smart_lst_selected = this.smart_lst;
    },
    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
    get_suppliers() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "suppliers",
        filters: [],
      };
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        this.suppliers = data;
      });
    },
    add_to_supplier(e) {
      this.item = { ...e };
      this.store = this.supplier_selected.code;
      this.item.store = this.supplier_selected.code;
      this.item.supplier = this.supplier_selected.code;
      this.valid = false;
      this.newItem = false;
      this.dialog = true;
    },
    get_smart_lst() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "raw_material",
        filters: [{ field: "store", operator: "=", value: "000000" }],
      };
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        var ctgry = data.reduce(function (r, a) {
          r[a.category] = r[a.category] || [];
          r[a.category].push(a);
          return r;
        }, Object.create(null));

        for (const [key] of Object.entries(ctgry)) {
          ctgry[key].sort(function (a, b) {
            var textA = a.product_name.toUpperCase();
            var textB = b.product_name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }
        this.smart_ctgry = ctgry;
        this.smart_categories = Object.keys(ctgry).sort();
        this.smart_lst_selected = data;

        console.log(ctgry, data);
        this.loading_status = false;
        this.smart_lst = data;
      });
    },
    handleClick(data) {

      this.store = data.store;
      this.item = { ...data };

      this.newItem = false;
      this.dialog = true;
    },
    get_items(e) {
      if (e) {
        this.supplier_selected = e;
      }
      this.items = [];
      this.loading_status = true;
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [
          { field: "store", operator: "=", value: this.supplier_selected.code },
        ],
      };
      webserver("get_table", qry, (data) => {
        this.loading_status = false;

        if (data.length !== 0) {
          data.forEach((rg) => {
            if (rg.cost_uom) rg.cost_uom = parseFloat(rg.cost_uom);
          });

          var ctgry = data.reduce(function (r, a) {
            r[a.category] = r[a.category] || [];
            r[a.category].push(a);
            return r;
          }, Object.create(null));

          for (const [key] of Object.entries(ctgry)) {
            ctgry[key].sort(function (a, b) {
              var textA = a.product_name.toUpperCase();
              var textB = b.product_name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
          }
          this.byCategories = ctgry;
          this.categories = Object.keys(ctgry).sort();

          var sbctgry = data.reduce(function (r, a) {
            r[a.subcategory] = r[a.subcategory] || [];
            r[a.subcategory].push(a);
            return r;
          }, Object.create(null));
          this.subcategories = Object.keys(sbctgry).sort();

          var brnd = data.reduce(function (r, a) {
            r[a.brand] = r[a.brand] || [];
            r[a.brand].push(a);
            return r;
          }, Object.create(null));
          this.brands = Object.keys(brnd).sort();
          this.items = data;
        }
        if (this.$vuetify.breakpoint.xsOnly) {
          this.dialog_suppliers = false;
        }
      });
    },
    newForm() {
      this.item = createItem();
      this.store = "000000";
      this.item.account = this.$store.state.profile.account;
      this.item.store = "000000";
      this.item.supplier = "SCP";
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    refresh() {
      this.dialog = false;
      if (this.newItem) {
         this.get_smart_lst();
      } else {
        this.get_items();
      }
    },
    getImagePhoto: function (account, code, src) {
      var url =
        "https://smartchef.pro/photos/" + account + "/" + code + "/" + src;
      return url;
    },
    changeMenu(e) {
      this.search_smart = "";
      this.smart_lst_selected = this.smart_ctgry[e];
      console.log(this.smart_lst_selected, this.smart_ctgry, e);
    },
  },
  watch: {
    s_expand: function (e) {
      console.log(e);
      if (e) {
        this.smart_width = 800;
      } else {
        this.smart_width = 450;
      }
    },
  },
};
</script>

<style>
.file-input {
  display: none;
}
.centered-input input {
  text-align: center;
}
.table-cursor tbody tr:hover {
  cursor: pointer;
}

.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0;
  z-index: 2;
}
.fixed-bar-b {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 56px;
  z-index: 2;
}

.float {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 70px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 2;
}
</style>
